import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getProductCategories (token: string, perPage: number, page: number, signal: AbortSignal, search?: string, filterHidden:string = '') {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/product-categories?${filterHidden}`, config)
  },
  addProductCategory (token: string, productCategory: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/product-categories', { productCategory }, config)
  },
  getProductCategoryById (token: string, productCategoryId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/product-categories/${productCategoryId}`, config)
  },
  editProductCategoryById (token: string, productCategoryId: string, productCategory: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/product-categories/${productCategoryId}`, { productCategory }, config)
  },
  updateProductCategoriesSortOrder (token: string, productCategories: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put('/product-categories', { productCategories }, config)
  },
  deleteProductCategoryById (token: string, productCategoryId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/product-categories/${productCategoryId}`, config)
  },
  addTagToProductCategory (token: string, productCategoryId: string, productCategoryTag: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/product-categories/${productCategoryId}/tags`, { productCategoryTag }, config)
  },
  getTagsOfProductCategory (token: string, perPage: number, page: number, productCategoryId: string, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/product-categories/${productCategoryId}/tags`, config)
  },
  addProductsToProductCategory (token: string, productCategoryId: string, productCategory: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/product-categories/${productCategoryId}/products`, { productCategory }, config)
  },
  getProductsOfProductCategory (token: string, perPage: number, page: number, productCategoryId: string, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/product-categories/${productCategoryId}/products`, config)
  }
}
