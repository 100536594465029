import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface Chat {
  id: number
  name: string
  previewUrl?: string
  message: string
  time: string
  date: string
  isUser: boolean
  logo?: string
}

interface Product {
  id: number
  name: string
  price: number
  imageUrl: string
  isApproved: boolean
  isDisApproved: boolean
  quantity: number
  chat: Chat[]
  Color: string
  Branding: string
}

interface DummyProductsState {
  products: Product[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: DummyProductsState = {
  products: [],
  status: 'idle',
  error: null
}

const dummyProducts = [
  {
    id: 1,
    name: 'Product A',
    price: 100,
    imageUrl: 'product1',
    isApproved: false,
    isDisApproved: false,
    quantity: 10,
    Color: 'white',
    Branding: '3c',

    chat: [
      {
        id: 1,
        name: 'printPreviewUpdload',
        previewUrl:
          'https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link',
        message: 'Print Preview Uploaded',
        time: '10:00',
        date: '2021.09.01',
        isUser: false
      },
      {
        id: 2,
        name: 'PrintDisappoved',
        message: 'Print Disapproved',
        time: '10:01',
        date: '2021.09.01',
        isUser: true
      },
      {
        id: 3,
        name: 'uploadLogo',
        message:
          'Add the new logo to the bottom o the aarticle and make it as big as possible',
        logo: 'https://www.w3schools.com/w3css/img_lights.jpg',
        time: '10:02 AM',
        date: '2021.09.01',
        isUser: false
      },
      {
        id: 1,
        name: 'printPreviewUpdload',
        previewUrl:
          'https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link',
        message: 'Print Preview Uploaded',
        time: '10:00',
        date: '2021.09.01',
        isUser: false
      }
    ]
  },
  {
    id: 2,
    name: 'Product B',
    price: 200,
    imageUrl: 'product2',
    isApproved: false,
    isDisApproved: false,
    quantity: 5,
    Color: 'blue',
    Branding: '1C',
    chat: [
      {
        id: 1,
        name: 'printPreviewUpdload',
        previewUrl:
          'https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link',
        message: 'Print Preview Uploaded',
        time: '10:00',
        date: '2021.09.01',
        isUser: false
      },
      {
        id: 2,
        name: 'PrintDisappoved',
        message: 'Print Disapproved',
        time: '10:01',
        date: '2021.09.01',
        isUser: true
      },
      {
        id: 3,
        name: 'uploadLogo',
        message:
          'Add the new logo to the bottom o the aarticle and make it as big as possible',
        logo: 'https://www.w3schools.com/w3css/img_lights.jpg',
        time: '10:02 AM',
        date: '2021.09.01',
        isUser: false
      },
      {
        id: 1,
        name: 'printPreviewUpdload',
        previewUrl:
          'https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link',
        message: 'Print Preview Uploaded',
        time: '10:00',
        date: '2021.09.01',
        isUser: false
      }
    ]
  },
  {
    id: 3,
    name: 'Product C',
    price: 300,
    imageUrl: 'product3',
    isApproved: false,
    isDisApproved: false,
    quantity: 20,
    Color: 'blue',
    Branding: 'Branding',
    chat: [
      {
        id: 1,
        name: 'printPreviewUpdload',
        previewUrl:
          'https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link',
        message: 'Print Preview Uploaded',
        time: '10:00',
        date: '2021.09.01',
        isUser: false
      },
      {
        id: 2,
        name: 'PrintDisappoved',
        message: 'Print Disapproved',
        time: '10:01',
        date: '2021.09.01',
        isUser: true
      },
      {
        id: 3,
        name: 'uploadLogo',
        message:
          'Add the new logo to the bottom o the aarticle and make it as big as possible',
        logo: 'https://www.w3schools.com/w3css/img_lights.jpg',
        time: '10:02 AM',
        date: '2021.09.01',
        isUser: false
      },
      {
        id: 1,
        name: 'printPreviewUpdload',
        previewUrl:
          'https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link',
        message: 'Print Preview Uploaded',
        time: '10:00',
        date: '2021.09.01',
        isUser: false
      }
    ]
  },
  {
    id: 4,
    name: 'Product D',
    price: 300,
    imageUrl: 'product4',
    isApproved: false,
    isDisApproved: false,
    quantity: 20,
    Color: 'black',
    Branding: 'Branding',
    chat: [
      {
        id: 1,
        name: 'printPreviewUpdload',
        previewUrl:
          'https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link',
        message: 'Print Preview Uploaded',
        time: '10:00',
        date: '2021.09.01',
        isUser: false
      },
      {
        id: 2,
        name: 'PrintDisappoved',
        message: 'Print Disapproved',
        time: '10:01',
        date: '2021.09.01',
        isUser: true
      },
      {
        id: 3,
        name: 'uploadLogo',
        message:
          'Add the new logo to the bottom o the aarticle and make it as big as possible',
        logo: 'https://www.w3schools.com/w3css/img_lights.jpg',
        time: '10:02 AM',
        date: '2021.09.01',
        isUser: false
      },
      {
        id: 1,
        name: 'printPreviewUpdload',
        previewUrl:
          'https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link',
        message: 'Print Preview Uploaded',
        time: '10:00',
        date: '2021.09.01',
        isUser: false
      }
    ]
  },
  {
    id: 5,
    name: 'Product E',
    price: 300,
    imageUrl: 'product5',
    isApproved: false,
    isDisApproved: false,
    quantity: 20,
    Color: 'blue',
    Branding: 'Branding',
    chat: [
      {
        id: 1,
        name: 'printPreviewUpdload',
        previewUrl:
          'https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link',
        message: 'Print Preview Uploaded',
        time: '10:00',
        date: '2021.09.01',
        isUser: false
      },
      {
        id: 2,
        name: 'PrintDisappoved',
        message: 'Print Disapproved',
        time: '10:01',
        date: '2021.09.01',
        isUser: true
      },
      {
        id: 3,
        name: 'uploadLogo',
        message:
          'Add the new logo to the bottom o the aarticle and make it as big as possible',
        logo: 'https://www.w3schools.com/w3css/img_lights.jpg',
        time: '10:02 AM',
        date: '2021.09.01',
        isUser: false
      },
      {
        id: 1,
        name: 'printPreviewUpdload',
        previewUrl:
          'https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link',
        message: 'Print Preview Uploaded',
        time: '10:00',
        date: '2021.09.01',
        isUser: false
      }
    ]
  }
]

export const fetchDummyProducts = createAsyncThunk(
  'dummyProducts/fetchDummyProducts',
  async () => {
    return new Promise<Product[]>((resolve) => {
      setTimeout(() => resolve(dummyProducts), 1000)
    })
  }
)
export const approveDesign = createAsyncThunk(
  'dummyProducts/approveDesign',
  async (productId: number) => {
    return new Promise<number>((resolve) => {
      setTimeout(() => resolve(productId), 1)
    })
  }
)
export const disapproveDesign = createAsyncThunk(
  'dummyProducts/disapproveDesign',
  async (productId: number) => {
    return new Promise<number>((resolve) => {
      setTimeout(() => resolve(productId), 1)
    })
  }
)
export const addComment = createAsyncThunk(
  'dummyProducts/addComment',
  async ({
    productId,
    message,
    file
  }: {
    productId: number
    message: string
    file?: File
  }) => {
    return new Promise<number>((resolve) => {
      setTimeout(() => resolve(productId), 0)
    })
  }
)

const dummyProductsSlice = createSlice({
  name: 'dummyProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(disapproveDesign.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(disapproveDesign.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const productId = action.payload
        const product = state.products.find(
          (product) => product.id === productId
        )
        if (product) {
          product.chat.push({
            id: 1,
            name: 'PrintDisappoved',
            message: 'Print Disapproved',
            time: new Date().toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit'
            }),
            date: new Date().toISOString().split('T')[0].replace(/-/g, '.'),
            isUser: true
          })
          product.isDisApproved = true
          product.isApproved = false
        }
      })
      .addCase(disapproveDesign.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
      })
      .addCase(approveDesign.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(approveDesign.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const productId = action.payload
        const product = state.products.find(
          (product) => product.id === productId
        )
        if (product) {
          product.isApproved = true
          product.isDisApproved = false

          product.chat.push({
            id: 1,
            name: 'PrintAppoved',
            message: 'Product Approved',
            time: new Date().toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit'
            }),
            date: new Date().toISOString().split('T')[0].replace(/-/g, '.'),
            isUser: true
          })
        }
      })
      .addCase(approveDesign.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
      })
      .addCase(fetchDummyProducts.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchDummyProducts.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.products = action.payload
      })
      .addCase(fetchDummyProducts.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
      })
      .addCase(addComment.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const { productId, message, file } = action.meta.arg
        const product = state.products.find(
          (product) => product.id === productId
        )
        if (product) {
          product.chat.push({
            id: product.chat.length + 1,
            name: 'uploadLogo',
            message,
            time: new Date().toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit'
            }),
            date: new Date().toISOString().split('T')[0],
            isUser: true,
            logo: file ? 'URL.createObjectURL(file)' : undefined
          })
        }
      })
      .addCase(addComment.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message ?? null
      })
  }
})

export default dummyProductsSlice.reducer
