import React from 'react'
interface Chat {
  id: number
  name: string
  message: string
  time: string
  date: string
  isUser: boolean
  logo?: string
}

interface Product {
  id: number
  name: string
  price: number
  imageUrl: string
  isApproved: boolean
  isDisApproved: boolean
  quantity: number
  chat: Chat[]
  Color: string
  Branding: string
}
interface BrandedProps {
  setSelectedProducts: React.Dispatch<React.SetStateAction<number>>
  dummyProducts: Product[]
  handleTab: (tab: string) => void
}

const Branded: React.FC<BrandedProps> = ({
  setSelectedProducts,
  dummyProducts,
  handleTab
}) => {
  return (
    <div>
      <div className="card p-0 h-100 ">
        <div className="p-2">
          <h2 className="h5 m-0">Branded Products</h2>
        </div>
        <div className="overflow-auto m-0">
          <table
            className="table table-responsive border-top mb-0"
            style={{ minWidth: '35rem' }}
          >
            <thead>
              <tr>
                <th scope="col ">Product</th>
                <th scope="col text-center">
                  <p className="m-0 text-center">Amount</p>
                </th>
                <th scope="col text-center">
                  <p className="m-0 text-center">Color</p>
                </th>
                <th scope="col text-center">
                  <p className="m-0 text-center">Branding</p>
                </th>
                <th scope="col text-center">
                  <p className="m-0 text-center">Design Status</p>
                </th>
                <th scope="col text-center">
                  <p className="m-0 text-center">Details</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {dummyProducts.map((product) => (
                <tr key={product.id}>
                  <td className=" ">{product.name}</td>
                  <td className=" text-center">
                    <div className="p-1">{product.quantity}sd</div>
                  </td>
                  <td className=" text-center">
                    <div className="p-1">{product.Color}</div>
                  </td>
                  <td className=" text-center">
                    <div className="p-1">{product.Branding}</div>
                  </td>

                  <td className=" text-center">
                    <div
                      className={`  rounded ${
                        product.isApproved
                          ? 'bg-sent'
                          : product.isDisApproved
                          ? 'bg-inprogress'
                          : 'bg-delivered'
                      } p-1`}
                    >
                      {product.isApproved
                        ? 'Approved'
                        : product.isDisApproved
                          ? 'Disapproved'
                          : 'In Progress'}
                    </div>
                  </td>
                  <td className=" text-center">
                    <p
                      className="text-primary h6 p-1"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedProducts(product.id)
                        handleTab('Print Preview')
                      }}
                    >
                      View Details
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Branded
