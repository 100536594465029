import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  approveDesign,
  disapproveDesign,
  fetchDummyProducts
} from '../../store/reducers/api/dummyProductsReducer'
import Progress from '../../components/loaders/Progress'
import Product1 from '../../assets/images/dummyAssets/photo5.png'
import Product2 from '../../assets/images/dummyAssets/photo2.jpg'
import Product3 from '../../assets/images/dummyAssets/photo3.png'
import Product4 from '../../assets/images/dummyAssets/photo4.png'
import Product5 from '../../assets/images/dummyAssets/photo1.jpg'
import PrintPreview from '../../components/BrandProducts/PrintPreview'
import DropZone from '../../components/Dropzone/DropZone'
import BrandedProducts from '../../components/BrandProducts/BrandedProducts'
import { TabNames } from '../../constants/branding'

const BrandProducts = () => {
  const dummyProducts = useAppSelector((state) => state.dummyProducts.products)
  const [selectedProduct, setSelectedProducts] = useState(0)
  const isLoading = useAppSelector((state) => state.dummyProducts.status === 'loading')
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [uploadedFileBuffer, setUploadedFileBuffer] = useState<File | null>(null)
  const [activeTab, setActiveTab] = useState<string>(
    TabNames.BrandedProducts
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchDummyProducts())
    if (dummyProducts.length > 0) {
      setSelectedProducts(dummyProducts[0].id || 0)
    }
  }, [])

  useEffect(() => {
    if (dummyProducts.length > 0) {
      setSelectedProducts(dummyProducts[0].id || 0)
    }
  }, [dummyProducts.length])

  const handleApprove = () => {
    dispatch(approveDesign(selectedProduct))
  }
  const handleDisapprove = () => {
    dispatch(disapproveDesign(selectedProduct))
  }

  const imgss: { [key: string]: string } = {
    product1: Product2,
    product2: Product3,
    product3: Product4,
    product4: Product5,
    product5: Product1
  }

  const handleTab = (tab: string) => {
    setActiveTab(tab)
  }

  const handleUpload = () => {
    setUploadedFile(uploadedFileBuffer)
  }

  const handleUploadBuffer = (file: File) => {
    setUploadedFileBuffer(file)
    setUploadError(null)
    setUploadProgress(0)
    const uploadSimulation = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(uploadSimulation)
          return 100
        }
        return prev + 10
      })
    }, 300)

    if (file.size > 2 * 1024 * 1024) {
      setUploadError('File size exceeds 2MB.')
      clearInterval(uploadSimulation)
    }
  }
  const handleDeleteFile = () => {
    setUploadedFile(null)
  }

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4 d-flex flex-column gap-3">
            <div className="navbar  navbar-expand mb-3 d-flex flex-column justify-content-start  align-items-start gap-2">
              <p className="h5">{activeTab === TabNames.BrandedProducts ? 'Branded Products Design Hub' : 'Project Progress'}</p>
              <div className='overflow-x-auto w-100'>
              <ul
                className="nav nav-tabs w-100 gap-2 md-border-primary flex-nowrap"
                role="tablist"
              >
              <li
                className={`${
                  activeTab === TabNames.BrandedProducts
                    ? 'border-primary'
                    : ''
                } border-top border-start border-end rounded-top nav-item`}
              >
                <a
                  className={`nav-link ${
                    activeTab === TabNames.BrandedProducts ? 'active' : ''
                  } text-secondary rounded-top`}
                  href="#"
                  role="tab"
                  onClick={() => handleTab(TabNames.BrandedProducts)}
                >
                  <p className="text-secondary m-0 tab-text text-nowrap">Branded Products</p>
                </a>
              </li>
              <li
                className={`${
                  activeTab === TabNames.PrintPreview ? 'border-primary' : ''
                } border-top border-start border-end rounded-top nav-item`}
              >
                <a
                  className={`nav-link ${
                    activeTab === TabNames.PrintPreview ? 'active' : ''
                  } text-secondary rounded-top`}
                  href="#"
                  role="tab"
                  onClick={() => handleTab(TabNames.PrintPreview)}
                >
                  <p className="text-secondary m-0 tab-text text-nowrap">Print Preview</p>
                </a>
              </li>
            </ul>
              </div>
            </div>
            {activeTab === TabNames.PrintPreview && (
              <PrintPreview
                dummyProducts={dummyProducts}
                selectedProduct={selectedProduct}
                isLoading={isLoading}
                imgss={imgss}
                setSelectedProducts={setSelectedProducts}
                uploadedFile = {uploadedFile}
                handleDeleteFile = {handleDeleteFile}
              />
            )}
            {activeTab === TabNames.BrandedProducts && (
              <BrandedProducts
                setSelectedProducts={setSelectedProducts}
                dummyProducts={dummyProducts}
                handleTab={handleTab}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="ApproveConfirmationModal"
        tabIndex={-1}
        aria-labelledby="ApproveConfirmationModal"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5
                className="modal-title text-primary"
                id="ApproveConfirmationModal"
              >
                <i className="bi bi-check-circle text-primary me-2"></i>Confirm
                Approval
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to approve the product{' '}
                <span className="fw-bold">{`${dummyProducts
                  .filter((product) => product.id === selectedProduct)
                  .map((product) => product.name)}`}</span>
                ?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={isLoading}
                data-bs-dismiss="modal"
                aria-label="Approve"
                onClick={handleApprove}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
      className="modal fade"
      id="updloadNewFileModal"
      data-bs-backdrop="static"
      tabIndex={-1}
      aria-labelledby="updloadNewFileModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1
              className="modal-title fs-5"
              id="updloadNewFileModalLabel"
            >
              <i className="bi bi-plus-circle"></i> Upload New File
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
            <div className="modal-body">
            <p>We recommend uploading a vector file for the logo upload. Vector files are AI, EPS, PDF or SVG formats.</p>
            <p>The file should not exceed 2MB.</p>
            <DropZone
              handleUpload={handleUploadBuffer}
              uploadProgress={uploadProgress}
              uploadError={uploadError}
              validWidth={48}
              validHeight={48}
            />
            </div>
            <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={handleUpload}
            >
              <i className='bi bi-upload me-2 ' style={{ fontSize: '1.2em' }}></i>Upload
            </button>
            </div>
        </div>
      </div>
    </div>
      <div
        className="modal fade"
        id="DisapproveConfirmationModal"
        tabIndex={-1}
        aria-labelledby="DisapproveConfirmationModal"
        aria-modal="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5
                className="modal-title text-danger"
                id="DisapproveConfirmationModal"
              >
                <i className="bi bi-x-circle text-danger me-2"></i>Confirm
                Disapproval
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to disapprove the product{' '}
                <span className="fw-bold">{`${dummyProducts
                  .filter((product) => product.id === selectedProduct)
                  .map((product) => product.name)}`}</span>
                ?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                disabled={isLoading}
                data-bs-dismiss="modal"
                aria-label="Disapprove"
                onClick={handleDisapprove}
              >
                Disapprove
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default BrandProducts
