import React, { useEffect, useRef, useState } from 'react'
import Placeholder from '../../assets/images/placeholder.png'
import { TrashIcon } from '../icons/TrashIcon'
import { addComment } from '../../store/reducers/api/dummyProductsReducer'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

interface Chat {
  id: number
  name: string
  message: string
  time: string
  date: string
  isUser: boolean
  logo?: string
}

interface Product {
  id: number
  name: string
  price: number
  imageUrl: string
  isApproved: boolean
  isDisApproved: boolean
  quantity: number
  chat: Chat[]
}

interface PrintPreviewProps {
  dummyProducts: Product[]
  selectedProduct: number
  isLoading: boolean
  imgss: { [key: string]: string }
  setSelectedProducts: (id: number) => void
  uploadedFile: File | null
  handleDeleteFile: () => void
}

const PrintPreview: React.FC<PrintPreviewProps> = ({
  dummyProducts,
  selectedProduct,
  isLoading,
  imgss,
  setSelectedProducts,
  uploadedFile,
  handleDeleteFile
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const [commentMessage, setCommentMessage] = useState<string>('')
  const dispatch = useAppDispatch()
  const profile = useAppSelector((state) => state.profile)

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [dummyProducts, selectedProduct])

  const handleAddComment = () => {
    const productId = dummyProducts.find(
      (product) => product.id === selectedProduct
    )?.id
    if (productId) {
      const payload = {
        productId,
        message: commentMessage,
        file: uploadedFile || undefined
      }
      dispatch(addComment(payload))
      handleDeleteFile()
      setCommentMessage('')
    }
  }
  return (
    <div>
      <div className="d-flex flex-column flex-md-row  gap-3 w-100">
        <div className=" gap-4 col-12 col-md-7 ">
          <div className="d-flex flex-column flex-md-row gap-4">
          <div className=" d-flex flex-column align-items-start">
            <p className="h5">&nbsp;</p>
            <div className="d-flex flex-column align-items-center w-100 gap-2">
              { isLoading
                ? (
                    Array.from(Array(2).keys()).map((n: number) => (
                    <img
                      key={n}
                      src={Placeholder}
                      alt="Product thumbnail"
                      className="img-fluid rounded mb-2 border"
                      style={{ objectFit: 'cover', maxHeight: 180 }}
                      role="button"
                      tabIndex={0}
                    />
                    ))
                  )
                : dummyProducts.length > 0
                  ? (
                      dummyProducts
                        .filter((product) => product.id !== selectedProduct)
                        .map((product, index) => (
                          <img
                            key={`product-${index}`}
                            src={
                              product.imageUrl ? imgss[product.imageUrl] : Placeholder
                            }
                            alt="Product thumbnail"
                            className="img-fluid rounded border"
                            style={{ objectFit: 'cover', height: 150, width: 140 }}
                            role="button"
                            tabIndex={0}
                            onClick={() => setSelectedProducts(product.id)}
                            onError={(e) => {
                              const target = e.target as HTMLImageElement
                              target.onerror = null
                              target.src = Placeholder
                            }}
                          />
                        ))
                    )
                  : (
                <img
                  src={Placeholder}
                  alt="Product thumbnail"
                  className="img-fluid rounded"
                  style={{ objectFit: 'cover', maxHeight: 180 }}
                />
                    )}
            </div>
          </div>
          <div className="col-12 col-md-9 d-flex flex-column align-items-start justify-content-between ">
            <p className="h5">
              {dummyProducts
                .filter((product) => product.id === selectedProduct)
                .map((product) => product.name)}
            </p>
            <div className="card d-flex flex-row justify-content-between align-items-center brandProductDisplay w-100 h-100">
              <div
                className="btn"
                onClick={() => {
                  const currentIndex = dummyProducts.findIndex(
                    (product) => product.id === selectedProduct
                  )
                  const nextIndex =
                    (currentIndex - 1 + dummyProducts.length) %
                    dummyProducts.length
                  setSelectedProducts(dummyProducts[nextIndex].id)
                }}
              >
                <i
                  className="bi bi-chevron-left fs-3 fw-bold"
                  role="button"
                  tabIndex={0}
                ></i>
              </div>
              <div className=" h-100 d-flex justify-content-center align-items-center  ">
                {isLoading
                  ? (
                    <img
                      src={Placeholder}
                      alt="Loading placeholder"
                      className="img-fluid"
                      style={{ objectFit: 'cover' }}
                    />
                    )
                  : (
                  <img
                    src={
                      imgss[
                        dummyProducts.find(
                          (product) => product.id === selectedProduct
                        )?.imageUrl ?? ''
                      ] || Placeholder
                    }
                    alt="Selected product"
                    className="img-fluid"
                    style={{ objectFit: 'cover' }}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement
                      target.onerror = null
                      target.src = Placeholder
                    }}
                  />
                    )}
              </div>
              <div
                className="btn"
                onClick={() => {
                  const currentIndex = dummyProducts.findIndex(
                    (product) => product.id === selectedProduct
                  )
                  const nextIndex = (currentIndex + 1) % dummyProducts.length
                  setSelectedProducts(dummyProducts[nextIndex].id)
                }}
              >
                <i
                  className="bi bi-chevron-right fs-3 fw-bold"
                  role="button"
                  tabIndex={0}
                ></i>
              </div>
            </div>
          </div>
          </div>
          <div className='d-flex gap-4'>
            <div style={{ width: 170 }} className='d-none d-md-block'></div>
            <div className="d-flex w-100 justify-content-center align-items-center gap-2 mt-3">
              <button
                className="btn btn-success text-center"
                data-bs-toggle="modal"
                data-bs-target="#ApproveConfirmationModal"
                aria-disabled={true}
                disabled={
                  dummyProducts.find(
                    (product) => product.id === selectedProduct
                  )?.isApproved
                }
              >
                Approve
              </button>
              <button
                className="btn btn-danger text-center"
                data-bs-toggle="modal"
                data-bs-target="#DisapproveConfirmationModal"
                disabled={
                  dummyProducts.find(
                    (product) => product.id === selectedProduct
                  )?.isDisApproved ||
                  dummyProducts.find(
                    (product) => product.id === selectedProduct
                  )?.isApproved
                }
              >
                Disapprove
              </button>
            </div>
          </div>
        </div>
        <div className=" d-flex flex-column align-items-start d w-100">
          <p className="h5">&nbsp;</p>
          <div className="card w-100" style={{ height: '550px' }}>
            <div className=" chatCard w-100 " style={{ minHeight: '550px' }}>
              <div
                className="border-bottom overflow-auto h-100 w-100"
                id="chat-container"
                ref={chatContainerRef}
                style={{ maxHeight: '500px' }}
              >
                {dummyProducts
                  .filter((product) => product.id === selectedProduct)
                  .map((product) => (
                    <div key={product.id} className="card-body">
                      {product.chat.map((chatItem, index) => (
                        <div
                          key={index}
                          className={`mb-2 ${
                            chatItem.name === 'PrintDisappoved'
                              ? 'bg-inprogress'
                              : ''
                          } ${
                            chatItem.name === 'PrintAppoved' ? 'bg-sent' : ''
                          } ${
                            chatItem.name === 'printPreviewUpdload'
                              ? 'bg-delivered'
                              : ''
                          }  ${
                            chatItem.name !== 'uploadLogo'
                              ? 'pt-3 pb-3 px-3'
                              : ''
                          } d-flex flex-wrap align-items-center justify-content-start rounded-5`}
                        >
                          {chatItem.name === 'printPreviewUpdload' && (
                            <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                              <p className="m-0  fw-bold">{chatItem.date}</p>
                              <p className="m-0  fw-bold">{chatItem.time}</p>
                              <p className="m-0">{chatItem.message}</p>
                              <a
                                href='https://drive.google.com/file/d/1pR_sZ1m-sn_bHQ1h0VqXjFjgOLzwT4EY/view?usp=drive_link'
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                print_preview.pdf
                              </a>
                            </div>
                          )}
                          {(chatItem.name === 'PrintDisappoved' ||
                            chatItem.name === 'PrintAppoved') && (
                            <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                              <p className="m-0 fw-bold">{chatItem.date}</p>
                              <p className="m-0 fw-bold">{chatItem.time}</p>
                              <p className="m-0">{chatItem.message}</p>
                            </div>
                          )}
                          {chatItem.name === 'uploadLogo' && (
                            <div className="d-flex flex-column align-items-start justify-content-center gap-2">
                              <div className="d-flex align-items-start justify-content-start gap-4">
                                <div>
                                  <div className="avatar bg-delivered d-flex align-items-center justify-content-center p-4">
                                    {profile.profile?.firstName?.charAt(0)}
                                    {profile.profile?.lastName?.charAt(0)}
                                  </div>
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="d-flex align-items-center justify-content-start gap-3">
                                    <p className="h6 m-0">{`${profile.profile?.firstName} ${profile.profile?.lastName}`}</p>
                                    <p className="m-0 fw-bold text-secondary">
                                      {chatItem.time}
                                    </p>
                                  </div>
                                  <p>{chatItem.message}</p>
                                  {chatItem.logo && (
                                    <div className="border p-2 px-3 d-flex align-items-center justify-content-start gap-3 rounded-5">
                                      <i className="bi bi-file-earmark fs-1"></i>
                                      <a
                                        href={`${chatItem.logo}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="h6"
                                      >
                                        new-logo-white.ai
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
              </div>

              {!dummyProducts.find((product) => product.id === selectedProduct)
                ?.isApproved
                ? (
                <div
                  className="d-flex align-items-center gap-2 justify-content-between p-3 px-2 "
                  style={{ width: '100%' }}
                >
                  <div
                    className="btn d-flex align-items-center justify-content-center border rounded-circle "
                    data-bs-toggle="modal"
                    data-bs-target="#updloadNewFileModal"
                  >
                    <i className="bi bi-paperclip m-0 fs-4 text-secondary"></i>
                  </div>

                  <div className="d-flex w-100">
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="Enter comment"
                      value={commentMessage}
                      onChange={(e) => setCommentMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleAddComment()
                        }
                      }}
                    />
                    <button
                      onClick={handleAddComment}
                      className="btn btn-primary"
                      disabled={
                        dummyProducts.find(
                          (product) => product.id === selectedProduct
                        )?.isApproved ||
                        (commentMessage === '' && !uploadedFile)
                      }
                    >
                      <i className="bi bi-send"></i>
                    </button>
                  </div>
                </div>
                  )
                : (
                <div className="d-flex justify-content-center align-items-center  p-2 rounded">
                  <p className="text-secondary m-0">Product Design Approved</p>
                </div>
                  )}
              {uploadedFile && (
                <div className="d-flex align-items-center gap-2 p-3 px-2 w-100 ">
                  <div className="d-flex align-items-center justify-content-between border p-2 rounded w-100">
                    <div className="d-flex align-items-center gap-2">
                      <i className="bi bi-file-earmark fs-4"></i>

                      <span
                        className="d-block d-md-none text-truncate"
                        style={{ maxWidth: 75 }}
                        title={uploadedFile.name}
                      >
                        {uploadedFile.name}
                      </span>
                      <span
                        className="d-none d-md-block"
                        title={uploadedFile.name}
                      >
                        {uploadedFile.name}
                      </span>
                    </div>

                    <button
                      type="button"
                      title="Remove Item"
                      className="btn btn-outline-danger btn-round btn-sm ms-5"
                      onClick={handleDeleteFile}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrintPreview
