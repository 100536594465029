import React from 'react'
import { useAppSelector } from '../../../store/hooks'
import { NavLink } from 'react-router-dom'

const DesignHub = () => {
  const dummyProducts = useAppSelector((state) => state.dummyProducts.products)

  return (
    <div className='card p-4 d-flex flex-col gap-4 my-2'>
      <div className='d-flex align-items-center  '>
      <p className='h5 m-0 '> Branded products Design Hub </p>
        {dummyProducts.length > 0 && (
          <span className="mx-2 bg-danger rounded-circle d-inline-flex justify-content-center align-items-center px-2 ">
            <p className="m-0 p-0 text-white">{dummyProducts.length}</p>
          </span>
        )}
      </div>
      <NavLink className='btn border d-flex justify-content-between' to="/brand-products"> <p className='m-0'>View and Approve Designs</p> <i className="bi bi-chevron-double-right"></i> </NavLink>
    </div>
  )
}

export default DesignHub
